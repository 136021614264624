import { TabsName } from '../core/tabsName'
import { AppErrorType } from './appErrorType'
import moment from 'moment'

export const initialState: ConfigState = {
  CustomerSupportLink: '',
  PrivacyPolicyUrl: '',
  TenantID: '',
  TenantLogoURL: '',
  TermsAndConditionsUrl: '',
  InviteCodeTitle: '',
  IsStandalonModeSupported: false,
  IsInviteCodeMandatory: false,
  InviteCodeDescription: '',
  IsReferralAvailable: false,
  WordsForPoints: {
    long: null,
    short: null,
    title: null
  },
  ui: {
    activeTab: TabsName.Earn,
    loadedList: [],
    isWelcome: false,
    isOffline: false,
    isShake: false,
    isSignupWelcome: false
  },
  startAppUTC: moment.utc().toISOString(),
  SignUpRewardEarningTitle: '',
  OptBackInTitle: '',
  OptBackInSubTitle: '',
  OptBackInDescription: '',
  SuspensionPageTitle: '',
  SuspensionPageDescription: '',
  AppErrorType: AppErrorType.none,
  SignUpWelcomeButtonLabel: null,
  SignUpWelcomeDescription: null,
  SignUpWelcomeImage: null,
  SignUpWelcomeTitle: null,
  SupportPhoneNumber: '',
  DisableHelp: false,
  IsInviteCodeDisplay: false,
  DisableTutorial: false,
  SignUpScreenTitle: null,
  SignUpScreenImage: null,
  WelcomeButtonLabel: null,
  TutorialSteps: [],
  MoreInformationLink: '',
  IANATimezone: '',
  Step1ImageURL: '',
  WelcomeScreenConfig: {
    TenantID: '',
    InviteCodeTitle: '',
    InviteCodeDescription: '',
    IsInviteCodeMandatory: false,
    IsInviteCodeDisplay: false,
    SignUpScreenImage: null,
    WelcomeButtonLabel: null,
    PrivacyPolicyURL: '',
    TermsAndConditionsURL: '',
    MoreInformationLink: '',
    MoreInformationLinkURL: '',
    MoreInformationLinkDescription: ''
  },
  WelcomeStepsConfig: [],
  WelcomeStepsConfigSignIn: [],
  SupportLanguage: false,
  LanguageList: [],
  CssTheme: '',
  SignInScreenImage: null,
  SignInScreenTitle: null,
  IsUserIDDisplay: false,
  TenantHeaderLogoURL: '',
  FavoriteOffersLimit: 0,
  IsPwaFlowVisible: false,
  ReactivationTitle: null,
  ReactivationDescription: null,
  ReactivationButtonLabel: null,
  ReactivationImageURL: null,
  IsWelcomeScreenSelector: false
}

export const defaultAction: ConfigAction = {
  type: '',
  payload: { ...initialState }
}
